<template>
    <div class="step-content-wrapper step-total">
        <div class="title h4">{{ data.caption }}</div>
        <div class="box">
            <div class="row">
                <div class="col-12 col-md-12 col-xl-8">
                    <!-- ----------------------- -->
                    <template v-for="(cat,key) in $store.state.basket">
                        <div class="step-box" :class="{'total-box':key=='total'}">
                            <div class="row no-gutters">
                                <template v-for="el in cat.item">
                                    <price-line
                                        :caption="el.caption"
                                        :count="el.count"
                                        :price="el.price"
                                    ></price-line>
                                </template>
                            </div>
                        </div>
                    </template>
                    <button class="form-control" @click="newCalc()">Новый расчет</button>
                </div>
                <div class="col-12 col-md-12 col-xl-4">
                    <div class="form-calc">
                        <div class="response" :class="{active:responseActive}">
                            <span>{{responseMsg}}</span>
                            <button class="form-control" @click="responseActive=false">Закрыть</button>
                        </div>
                        <div class="title h4">Оформить заказ</div>
                        <label for="for-calc-name">Имя</label>
                        <input type="text" class="form-control" v-model="form.name" id="form-calc-name">
                        <label for="for-calc-tel">Телефон</label>
                        <input type="text" class="form-control" v-model="form.tel" id="form-calc-tel">                        
                        <label for="for-calc-email">E-mail</label>
                        <input type="text" class="form-control" v-model="form.email" id="form-calc-email">  
                        <button class="form-control" @click="sendCalc()">Отправить</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GetItem from "./GetItem.vue"
import PriceLine from "./PriceLine.vue"

export default {
    components: { GetItem, PriceLine },
    name: "StepPost",
    props: {
        calc: Object,
    },
    data() {
        return {
            data: {},
            step: [],
            basket: {},
            form:{name:"",tel:"",email:""},
            responseMsg:"",
            responseActive:false
        }
    },
    created() {
        this.data = this.calc.data.total
        this.$store.dispatch("basket")
    },
    updated() {
        this.data = this.calc.data.total
        this.$store.dispatch("total")
        // this.$store.dispatch("basket")
    },
    methods: {
        basketJson: function () {
            this.$set(this.basket, "post", {})
            this.basket["post"].caption = this.calc.data.post.caption
        },
        getLabelPost: function (n) {
            let label = "1 пост"
            if (n > 1) label = n + " поста"
            if (n > 4) label = n + " постов"
            return label
        },
        getLabelController: function (n) {
            let label = "Без пультов"
            if (n == 1) label = n + " пульт"
            if (n > 1) label = n + " пульта"
            if (n > 4) label = n + " пультов"
            return label
        },
        getLabelSink: function (n) {
            let label = "Без раковин"
            if (n == 1) label = n + " раковина"
            if (n > 1) label = n + " раковины"
            if (n > 4) label = n + " раковин"
            if (n == 21) label = n + " раковина"
            return label
        },
        getLabelStove: function (n) {
            let label = "Без плит"
            if (n == 1) label = n + " плита"
            if (n > 1) label = n + " плиты"
            if (n > 4) label = n + " плит"
            return label
        },

        getLabelFloor: function (n) {
            let label = "Без пола"
            if (n == 1) label = n + " пол"
            if (n > 1) label = n + " пола"
            if (n > 4) label = n + " полов"
            return label
        },
        newCalc: function () {
            this.$store.commit("cloneData")
            this.$store.state.calc.step = this.$store.state.calc.steps[0]
        },        
        sendCalc: function(){
            let self = this
            self.responseMsg = "Отправляем..."
            self.responseActive = true
            this.axios
                .post(this.$store.state.get, {
                    event: "send-calc",
                    param: {
                        form: this.form,
                        basket: this.$store.state.basket
                    },
                })
                .then(function (response) {
                    self.responseMsg = response.data.response
                    self.responseActive = true
                })
                .catch(function (error) {
                    // handle error
                    console.log("response error")
                })
        }
    },
}
</script>


