<template>
    <div class="step-content-wrapper step-modul">
        <div class="title h4">{{ data.caption }}</div>
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="box">
                    <template v-for="n in 9">
                        <radio
                            :active="n == data.count"
                            :label="getLabel(n)"
                            @click="setCount(n)"
                        ></radio>
                    </template>
                </div>
                <div class="box">
                    <div class="title-label">Тип</div>
                    <template v-for="(label, type) in data.types">
                        <radio
                            :active="type == data.type"
                            :label="label"
                            @click="setType(type)"
                        ></radio>
                    </template>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <div class="box items-search-wrapper">
                    <div class="no-items" v-if="!loading&&items.length==0">
                        Ничего не найдено!
                    </div>
                    <div class="div" v-if="!loading&&items.length>0">
                        <div class="row">
                            <div class="col-12 col-lg-9">
                                <div class="title-label">Длинна</div>
                                <Slider
                                    v-model="data.long"
                                    :min="lminmax[0]"
                                    :max="lminmax[1]"
                                    :format="format"
                                    class="slider-color"
                                ></Slider>
                            </div>
                            <div class="col-12 col-lg-3">
                                <div class="title-label">Ширина</div>
                                <Slider
                                    v-model="data.width"
                                    :min="wminmax[0]"
                                    :max="wminmax[1]"
                                    :format="format"
                                    class="slider-color"
                                ></Slider>
                            </div>
                        </div>
                        <div class="items">
                            <div class="row item-list">
                                <template v-for="item in items">
                                    <div
                                        class="col col-12 col-md-6 col-xl-4"
                                        v-if="
                                            item.size.long >= data.long[0] &&
                                            item.size.long <= data.long[1] &&
                                            item.size.width >= data.width[0] &&
                                            item.size.width <= data.width[1]
                                        "
                                    >
                                        <div class="item-select" @click="selectItem(item)" :class="{active:item==data.item}">
                                            <div
                                                class="image"
                                                :style="{
                                                    backgroundImage:
                                                        'url(' +
                                                        item.image +
                                                        ')',
                                                }"
                                            >
                                                <div class="selected"><div class="cap">Выбрано</div></div>
                                                <div class="size">
                                                    {{ item.size.long }}м x
                                                    {{ item.size.width }}м x
                                                    {{ item.size.height }}м
                                                </div>
                                            </div>
                                            <div class="name">
                                                {{ item.name }}
                                            </div>

                                            <div
                                                class="price"
                                                v-if="item.price > 0"
                                            >
                                                {{ item.price }} ₽
                                            </div>
                                            <a
                                                :href="item.url"
                                                class="url"
                                                target="_blnak"
                                                ><img
                                                    src="/vuecalc/icons/external-link-alt-solid.svg"
                                                    alt=""
                                                />Подробнее</a
                                            >
                                            <a
                                                v-if="item.file != ''"
                                                :href="item.file"
                                                class="pdf"
                                                target="_blnak"
                                                ><img
                                                    src="/vuecalc/icons/file-pdf.svg"
                                                    alt=""
                                                />Коммерческое<br />предложение</a
                                            >
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="div" v-if="loading">
                        <div class="loading">Загрузка...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import radio from "@/components/radio.vue"
import check from "@/components/check.vue"
import Slider from "@vueform/slider"

export default {
    components: { radio, check, Slider },
    name: "StepPost",
    props: {
        calc: Object,
    },
    data() {
        return {
            data: {},
            lminmax: [0, 1],
            wminmax: [0, 1],
            items: [],
            format: function (value) {
                return Math.ceil(value) + `м`
            },
            loading: true,
        }
    },
    mounted() {
        this.getItems(true)
    },
    created() {
        this.data = this.calc.data.modul
        // this.total()
    },
    updated() {
        this.data = this.calc.data.modul
        this.$store.dispatch("total")
    },
    methods: {
        getItems: function (flag) {
            let self = this
            self.loading = true
            this.axios
                .post(this.$store.state.get, {
                    event: "get-modul-items",
                    param: {
                        count: self.data.count,
                        type: self.data.type,
                        lminmax: self.lminmax,
                        wminmax: self.wminmax,
                    },
                })
                .then(function (response) {
                    self.items = response.data.items
                    self.lminmax = response.data.lminmax
                    self.wminmax = response.data.wminmax
                    if (flag == true) {
                        self.data.long = response.data.lminmax
                        self.data.width = response.data.wminmax
                    }
                    self.loading = false
                })
                .catch(function (error) {
                    // handle error
                    console.log("response error, get post minmax")
                })
        },
        getLabel: function (n) {
            let label = "1 пост"
            if (n > 1) label = n + " поста"
            if (n > 4) label = n + " постов"
            return label
        },

        setCount: function (n) {
            this.data.count = n
            this.getItems(true)
        },
        setType: function (type) {
            this.data.type = type
            this.getItems(true)
        },
        selectItem: function (item) {
            this.data.item = item
        },        
    },
}
</script>
<style src="@vueform/slider/themes/default.css"></style>

