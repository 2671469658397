import { createStore } from 'vuex'

export default createStore({
  state: {
    get_: "http://zzz.spektrf.ru/calc-api",
    get: "https://spektrf.ru/calc-api",
    calc: {},
    step: "post",
    basket: {},
    types: [
      {
        caption: "Мойки самообслуживания",
        icon: "car-wash.svg",
        step: "post",
        steps: [
          "post", "controller", "equipment", "construction", "total"
        ],
        data: {}
      },
      {
        caption: "Модульные автомойки",
        icon: "car-wash-sys.svg",
        step: "modul",
        steps: ["modul", "total"],
        data: {}
      }
    ],
    data: {
      modul: {
        caption: "Модульные посты",
        count: 1,
        long: [0, 50],
        width: [0, 100],
        type: "car",
        types: {
          car: "Легковые",
          body: "Кузовные",
          cargo: "Грузовые",
          modul: "Модульные"
        },
        item: {
          id: 0,
          name: "",
          price: 0
        },
        total: 0
      },
      post: {
        caption: "Количество постов",
        pcount: 1,
        pleft: false,
        pright: false,
        count: 1,
        price: 1000,
        priceConsole: 500,
        total: 0
      },
      controller: {
        caption: "Конфигурация пультов",
        types: [
          {
            caption: "Пульты «Стандарт»",
            price: 1000,
            count: 0,
            gets: {
              function: [
                {
                  caption: "PayPass",
                  get: "calc_controller_standart.paypass",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Купюро-приемник",
                  get: "calc_controller_standart.kupur",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Монето-приемник",
                  get: "calc_controller_standart.monet",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                }
              ],
              equip: [
                {
                  caption: "Поворотные консоли",
                  get: "calc_controller_standart.rotate",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Пистолеты",
                  get: "calc_controller_standart.pistol",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Держатели для ковриков",
                  get: "calc_controller_standart.lock",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                }
              ]
            }
          },
          {
            caption: "Пульты «Премиум»",
            price: 2000,
            count: 0,
            gets: {
              function: [
                {
                  caption: "PayPass",
                  get: "calc_controller_premium.paypass",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Купюро-приемник",
                  get: "calc_controller_premium.kupur",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Монето-приемник",
                  get: "calc_controller_premium.monet",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                }],
              equip: [
                {
                  caption: "Поворотные консоли",
                  get: "calc_controller_premium.rotate",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Пистолеты",
                  get: "calc_controller_premium.pistol",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Держатели для ковриков",
                  get: "calc_controller_premium.lock",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                }
              ]
            }
          },
          {
            caption: "Пульты «Люкс»",
            price: 3000,
            count: 0,
            gets: {
              function: [
                {
                  caption: "PayPass",
                  get: "calc_controller_luxe.paypass",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Купюро-приемник",
                  get: "calc_controller_luxe.kupur",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Монето-приемник",
                  get: "calc_controller_luxe.monet",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                }],
              equip: [
                {
                  caption: "Поворотные консоли",
                  get: "calc_controller_luxe.rotate",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Пистолеты",
                  get: "calc_controller_luxe.pistol",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                },
                {
                  caption: "Держатели для ковриков",
                  get: "calc_controller_luxe.lock",
                  item: {
                    id: 0,
                    name: "",
                    image: "",
                    price: 0
                  },
                }
              ]
            }
          }
        ],
        total: 0
      },
      equipment: {
        caption: "Дополнительное оборудование",
        sinkprice: 100,
        sinkcount: 0,
        total: 0,
        gets: [
          {
            caption: "Пылесос",
            get: "calc_equipment.cleaner",
            item: {
              id: 0,
              name: "",
              image: "",
              price: 0
            },
          },
          {
            caption: "Осмос",
            get: "calc_equipment.osmos",
            item: {
              id: 0,
              name: "",
              image: "",
              price: 0
            }
          },
          {
            caption: "Водоочистка",
            get: "calc_equipment.water",
            item: {
              id: 0,
              name: "",
              image: "",
              price: 0
            }
          },
          {
            caption: "Водо-подготовка",
            get: "calc_equipment.waterset",
            item: {
              id: 0,
              name: "",
              image: "",
              price: 0
            }
          },
        ]
      },
      construction: {
        caption: "Параметры строительства",
        total: 0,
        stovecount: 0,
        stoveprice: 100,
        floorcount: 0,
        floorprice: 200,
        gets: {
          carport: [
            {
              caption: "Premium",
              get: "calc_construction_carport.premium",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
            {
              caption: "Econom",
              get: "calc_construction_carport.econom",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
            {
              caption: "Future",
              get: "calc_construction_carport.future",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            }
          ],
          boiler: [
            {
              caption: "Газовый",
              get: "calc_construction_boiler.gas",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
            {
              caption: "Дизельный",
              get: "calc_construction_boiler.disel",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },

          ],
          cleaner: [
            {
              caption: "Система очистки сточных вод",
              get: "calc_construction_cleaner.water",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
            {
              caption: "Отстойник",
              get: "calc_construction_cleaner.tank",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
          ],
          option: [
            {
              caption: "Цинкование",
              get: "calc_construction_option.cink",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
            {
              caption: "Технический контейнер",
              get: "calc_construction_option.container",
              item: {
                id: 0,
                name: "",
                image: "",
                price: 0
              }
            },
          ]
        }
      },
      washes: {
        caption: "Параметры мойки",
        total: 0,
      },
      total: {
        caption: "Итоговая стоимость",
        total: 0
      }
    }
  },
  mutations: {
    setCalc(state, param) {
      state.calc = state.types[param.type]
    },
    setStep(state, param) {
      state.calc.step = param.step
    },

    getPrices() {
    },
    cloneData(state) {
      state.types.forEach((element) => {
        element.data = JSON.parse(JSON.stringify(state.data))
      })
    },
    totalModul: function (state) {

      let total = 0
      if (state.calc.data.modul.item.id != 0) {
        total = state.calc.data.modul.item.price
      }
      state.calc.data.modul.total = total
    },
    totalPost: function (state) {
      let count = state.calc.data.post.pcount
      let total = 0

      if (state.calc.data.post.pleft) {
        count++
        total += state.calc.data.post.priceConsole
      }
      if (state.calc.data.post.pright) {
        count++
        total += state.calc.data.post.priceConsole
      }

      state.calc.data.post.count = count
      total += state.calc.data.post.price * state.calc.data.post.pcount
      state.calc.data.post.total = total
    },
    totalController: function (state) {
      let total = 0
      state.calc.data.controller.types.forEach(element => {
        total += element.price * element.count
        // подгружаемые товары
        for (let key in element.gets) {
          element.gets[key].forEach(el => {
            if (el.item.id != 0) {
              total += el.item.price
            }
          })
        }
      });
      state.calc.data.controller.total = total
    },
    totalEquipment: function (state) {
      let total = 0
      total += state.calc.data.equipment.sinkprice * state.calc.data.equipment.sinkcount

      state.calc.data.equipment.gets.forEach(element => {
        if (element.item.id != 0) {
          total += element.item.price
        }

      })
      state.calc.data.equipment.total = total
    },
    totalConstruction: function (state) {
      let total = 0
      // плиты
      total += state.calc.data.construction.stoveprice * state.calc.data.construction.stovecount
      // теплые полы
      total += state.calc.data.construction.floorprice * state.calc.data.construction.floorcount
      for (let key in state.calc.data.construction.gets) {
        state.calc.data.construction.gets[key].forEach(element => {
          if (element.item.id != 0) {
            total += element.item.price
          }
        })
      }

      state.calc.data.construction.total = total
    },
    totalTotal: function (state) {
      let total = 0
      state.calc.steps.forEach(step => {
        if (step != 'total')
          total += state.calc.data[step].total
      })
      state.calc.data.total.total = total

    },

    // объект корзины
    getBasket: function (state) {
      state.basket = {}

      // модульные
      if (this.getters.extStep("modul")) {
        let modul = state.calc.data.modul
        state.basket.modul = {}
        state.basket.modul.caption = modul.caption
        state.basket.modul.item = []
        state.basket.modul.item.push({
          caption: modul.item.name,
          count: 1,
          price: modul.item.price
        })
        // чистим если ничего не добавили
        if (state.basket.modul.item.length == 0) {
          delete state.basket.modul
        }
      }
      // посты
      if (this.getters.extStep("post")) {
        let post = state.calc.data.post
        state.basket.post = {}
        state.basket.post.caption = post.caption
        state.basket.post.item = []
        state.basket.post.item.push({
          caption: "Посты",
          count: this.getters.getLabelPost(post.count),
          price: post.price * post.count
        })
        if (post.pleft)
          state.basket.post.item.push({
            caption: "Консольный пост",
            count: "Левый",
            price: post.priceConsole
          })
        if (post.pright)
          state.basket.post.item.push({
            caption: "Консольный пост",
            count: "Правый",
            price: post.priceConsole
          })
        // чистим если ничего не добавили
        if (state.basket.post.item.length == 0) {
          delete state.basket.post
        }
      }
      // контроллеры
      if (this.getters.extStep("controller")) {
        let controller = state.calc.data.controller
        state.basket.controller = {}
        state.basket.controller.item = []
        state.basket.controller.caption = controller.caption
        for (var i = 0; i <= 2; i++) {
          if (controller.types[i].count > 0) {
            state.basket.controller.item.push({
              caption: controller.types[i].caption,
              count: this.getters.getLabelController(controller.types[i].count),
              price: controller.types[i].price * controller.types[i].count
            })
          }
          // обвесы
          for (var key in controller.types[i].gets) {
            controller.types[i].gets[key].forEach(el => {
              if (el.item.id !== 0)
                state.basket.controller.item.push({
                  caption: el.item.name,
                  price: el.item.price
                })
            })
          }
        }
        // чистим если ничего не добавили
        if (state.basket.controller.item.length == 0) {
          delete state.basket.controller
        }
      }
      if (this.getters.extStep("equipment")) {
        let equipment = state.calc.data.equipment
        state.basket.equipment = {}
        state.basket.equipment.item = []
        state.basket.equipment.caption = equipment.caption
        if (equipment.sinkcount > 0) {
          state.basket.equipment.item.push({
            caption: "Раковины",
            count: this.getters.getLabelSink(equipment.sinkcount),
            price: equipment.sinkprice * equipment.sinkcount
          })
        }
        equipment.gets.forEach(el => {
          if (el.item.id !== 0)
            state.basket.equipment.item.push({
              caption: el.item.name,
              price: el.item.price
            })
        })
        // чистим если ничего не добавили
        if (state.basket.equipment.item.length == 0) {
          delete state.basket.equipment
        }
      }
      // строительство
      if (this.getters.extStep("construction")) {
        let construction = state.calc.data.construction
        state.basket.construction = {}
        state.basket.construction.item = []
        state.basket.construction.caption = construction.caption
        if (construction.stovecount > 0) {
          state.basket.construction.item.push({
            caption: "Плиты",
            count: this.getters.getLabelStove(construction.stovecount),
            price: construction.stoveprice * construction.stovecount
          })
        }
        if (construction.floorcount > 0) {
          state.basket.construction.item.push({
            caption: "Теплый пол",
            count: this.getters.getLabelFloor(construction.floorcount),
            price: construction.floorprice * construction.floorcount
          })
        }
        // обвесы
        for (var key in construction.gets) {
          construction.gets[key].forEach(el => {
            if (el.item.id !== 0)
              state.basket.construction.item.push({
                caption: el.item.name,
                price: el.item.price
              })
          })
        }
        // чистим если ничего не добавили
        if (state.basket.construction.item.length == 0) {
          delete state.basket.construction
        }
      }
      // ИТОГО
      let total = state.calc.data.total
      state.basket.total = {}
      state.basket.total.caption = total.caption
      state.basket.total.item = []
      state.basket.total.item.push({
        caption: "ИТОГО",
        price: total.total
      })
    }

  },
  getters: {
    extStep: (state) => (step) => {
      console.log(step)
      let find = false
      state.calc.steps.forEach(el => {
        if (el === step) find = true
      })
      return find
    },
    getLabelPost: (state) => (n) => {
      let label = "1 пост"
      if (n > 1) label = n + " поста"
      if (n > 4) label = n + " постов"
      return label
    },
    getLabelController: (state) => (n) => {
      let label = "Без пультов"
      if (n == 1) label = n + " пульт"
      if (n > 1) label = n + " пульта"
      if (n > 4) label = n + " пультов"
      return label
    },
    getLabelSink: (state) => (n) => {
      let label = "Без раковин"
      if (n == 1) label = n + " раковина"
      if (n > 1) label = n + " раковины"
      if (n > 4) label = n + " раковин"
      if (n == 21) label = n + " раковина"
      return label
    },
    getLabelStove: (state) => (n) => {
      let label = "Без плит"
      if (n == 1) label = n + " плита"
      if (n > 1) label = n + " плиты"
      if (n > 4) label = n + " плит"
      return label
    },

    getLabelFloor: (state) => (n) => {
      let label = "Без пола"
      if (n == 1) label = n + " пол"
      if (n > 1) label = n + " пола"
      if (n > 4) label = n + " полов"
      return label
    }
  },
  actions: {
    total: function () {
      this.commit("totalModul")
      this.commit("totalPost")
      this.commit("totalController")
      this.commit("totalEquipment")
      this.commit("totalConstruction")
      this.commit("totalTotal")
    },
    basket: function () {
      this.commit("getBasket")
    }

  },
  modules: {
  }
})
