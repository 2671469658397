
<template v-if="calc.data.controller.types[1].count > 0">
    <div class="col-5 col-md-5 line">
        <div class="caption">
            {{ caption }}
        </div>
    </div>
    <div class="col-3 col-md-4 line">
        <div class="value" v-if="count">
            {{ count }}
        </div>
    </div>
    <div class="col-4 col-md-3 line">
        <div class="price">
            <template v-if="price>0">
            {{ price }}
            ₽
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "radio",
    props: {
        caption: String,
        count: String,
        price: Number,
        class: String
    },
    mounted() {},
}
</script>


