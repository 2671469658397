<template>
    <div class="step-content-wrapper step-post">
        <div class="title h4">{{ data.caption }}</div>
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="box pcounts">
                    <template v-for="n in 8">
                        <radio
                            :active="n == data.pcount"
                            :label="getLabel(n)"
                            @click="setCount(n)"
                        ></radio>
                    </template>
                </div>
                <div class="box pconsole">
                    <div class="title-label">Консольные посты</div>
                    <check
                        :active="data.pleft"
                        label="Левый"
                        @click="data.pleft=!data.pleft"
                    ></check>
                    <check
                        :active="data.pright"
                        label="Правый"
                        @click="data.pright=!data.pright"
                    ></check>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <img :src="getImage()" class="preview" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import radio from "@/components/radio.vue"
import check from "@/components/check.vue"
export default {
    components: { radio, check },
    name: "StepPost",
    props: {
        calc: Object,
    },
    data() {
        return {
            data: {},
        }
    },
    created() {
        this.data = this.calc.data.post
        // this.total()
    },
    updated() {
        this.data = this.calc.data.post
        this.$store.dispatch('total')
    },  
    methods: {

        getLabel: function (n) {
            let label = "1 пост"
            if (n > 1) label = n + " поста"
            if (n > 4) label = n + " постов"
            return label
        },

        setCount: function (n) {
            this.data.pcount = n
            // this.total()
        },
      
        getImage: function () {
            let img = this.data.pcount
            if (this.data.pleft) img = "_" + img
            if (this.data.pright) img = img + "_"
            return "/vuecalc/post/" + img + ".png"
        },
    },
}
</script>


