<template>
    <div class="step-content-wrapper step-equipment">
        <div class="title h4">{{ data.caption }}</div>
        <div class="box">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="title-label">Количество раковин</div>
                    <div class="row count">
                        <div class="col-12 col-xl-6">
                            <template v-for="(n, i) in 11">
                                <radio
                                    :active="i == data.sinkcount"
                                    :label="getLabel(i)"
                                    @click="setCount(i)"
                                ></radio>
                            </template>
                        </div>
                        <div class="col-12 col-xl-6">
                            <template v-for="(n, i) in 11">
                                <radio
                                    :active="i+11 == data.sinkcount"
                                    :label="getLabel(i+11)"
                                    @click="setCount(i+11)"
                                ></radio>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <div class="title-label func-label">Функции поста</div>
                    <div class="row item-list">
                        <template v-for="item in data.gets">
                            <div class="col col-12 col-md-6">
                                <get-item :data="item"></get-item>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import radio from "@/components/radio.vue"
import check from "@/components/check.vue"
import GetItem from "@/components/GetItem.vue"

export default {
    components: { radio, check, GetItem },
    name: "StepPost",
    props: {
        calc: Object,
    },
    data() {
        return {
            data: {},
        }
    },
    created() {
        this.data = this.calc.data.equipment
    },
    updated() {
        this.data = this.calc.data.equipment
        this.$store.dispatch("total")
    },
    methods: {
        total: function () {},
        getLabel: function (n) {
            let label = "Без раковин"
            if (n == 1) label = n + " раковина"
            if (n > 1) label = n + " раковины"
            if (n > 4) label = n + " раковин"
            if (n == 21) label = n + " раковина"
            return label
        },

        setCount: function (n) {
            this.data.sinkcount = n
        },
    },
}
</script>


