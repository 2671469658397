<template>
    <div class="item-comp">
        <div class="modal-window" :class="{ open: modalOpen }">
            <div class="window">
                <div class="close-window" @click="closeModal()">&times;</div>
                <div class="data-window">
                    <div class="row">
                        <template v-for="item in items">
                            <div class="col col-12 col-lg-4">
                                <div class="item-select" @click="setItem(item)">
                                    <div
                                        class="image"
                                        :style="{
                                            backgroundImage:
                                                'url(' + item.image + ')',
                                        }"
                                    ></div>
                                    <div class="name">{{ item.name }}</div>
                                    <div class="price">{{ item.price }} ₽</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="item" :class="{medium:type=='medium'}" @click.stop="getItem()" v-if="item.item.id == 0">
            <div class="desc">
                Выбрать <span>{{ item.caption }}</span>
            </div>
        </div>
        <div class="item-select" :class="{medium:type=='medium'}" v-if="item.item.id !== 0">
            <div class="delete" @click.stop="deleteItem(item.item)">
                &times;
            </div>
            <div
                class="image"
                :style="{ backgroundImage: 'url(' + item.item.image + ')' }"
            ></div>
            <div class="name">{{ item.item.name }}</div>
            <div class="price">{{ item.item.price }} ₽</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "get-item",
    props: {
        data: Object,
        type: String
    },
    data() {
        return {
            item: this.data,
            items: [],
            modalOpen: false,
        }
    },
    mounted() {},
    updated() {
        this.item = this.data
        this.$store.dispatch("total")
    },
    methods: {
        getItem: function () {
            let self = this
            self.modalOpen = true
            this.axios
                .post(this.$store.state.get, {
                    event: "get-items",
                    param: {
                        get: this.item.get,
                    },
                })
                .then(function (response) {
                    self.items = response.data
                })
                .catch(function (error) {
                    // handle error
                    console.log("response error")
                })
        },
        setItem: function (item) {
            this.item.item = item
            this.modalOpen = false
        },
        deleteItem: function (item) {
            this.item.item.id = 0
        },
        closeModal: function () {
            this.modalOpen = false
        },
    },
}
</script>


