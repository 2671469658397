<template>
  <div class="radio check" :class="{active:active}">
    <div class="point"></div>
    <div class="label">{{label}}</div>
  </div>
</template>

<script>
export default {
  name: 'check',
  props:{
    label: String,
    active:Boolean
  },
  mounted(){
    
  }

}
// v-if="step == $store.state.step"
</script>


