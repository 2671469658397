<template>
    <div class="step-content-wrapper step-controller">
        <div class="title h4">{{ data.caption }}</div>
        <div class="row type-list">
            <template v-for="type in data.types">
                <div class="col-12 col-md-4 col-xl-auto">
                    <div
                        class="type"
                        :class="{ active: type == curtype }"
                        @click="setType(type)"
                    >
                        {{ type.caption }}
                        <span class="count">{{ type.count }} шт</span>
                    </div>
                </div>
            </template>
        </div>
        <div class="box">
            <div class="row">
                <div class="col-12 col-md-3">
                    <div class="title-label">Количество</div>
                    <div class="count">
                        <template v-for="(n, i) in 6">
                            <radio
                                :active="i == curtype.count"
                                :label="getLabel(i)"
                                @click="setCount(i)"
                            ></radio>
                        </template>
                    </div>
                </div>
                <div class="col-12 col-md-9">
                    <div class="title-label func-label">Функции пульта</div>
                    <div class="row item-list">
                        <template v-for="item in curtype.gets.function">
                            <div class="col col-12 col-md-4 col-xl-4">
                                <get-item :data="item"></get-item>
                            </div>
                        </template>
                    </div>

                    <div class="title-label">В пульт входят</div>
                    <div class="row item-list">
                        <template v-for="item in curtype.gets.equip">
                            <div class="col col-12 col-md-4 col-xl-4">
                                <get-item :data="item"></get-item>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import radio from "@/components/radio.vue"
import check from "@/components/check.vue"
import GetItem from "@/components/GetItem.vue"

export default {
    components: { radio, check, GetItem },
    name: "StepPost",
    props: {
        calc: Object,
    },
    data() {
        return {
            data: {},
            curtype: {},
        }
    },
    created() {
        this.data = this.calc.data.controller
        this.curtype = this.data.types[0]
        // this.data.types[0].count = this.calc.data.post.count
    },
    updated() {
        this.data = this.calc.data.controller
        // this.data.types[0].count = this.calc.data.post.count
        this.$store.dispatch("total")
    },
    methods: {
        total: function () {},
        getLabel: function (n) {
            let label = "Без пультов"
            if (n == 1) label = n + " пульт"
            if (n > 1) label = n + " пульта"
            if (n > 4) label = n + " пультов"
            return label
        },
        setType: function (type) {
            this.curtype = type
        },
        setCount: function (n) {
            this.curtype.count = n
        },


    },
}
</script>


