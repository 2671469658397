<template>
    <div class="step-content-wrapper step-construction">
        <div class="title h4">{{ data.caption }}</div>
        <div class="box">
            <div class="row">
                <div class="col-12 col-md-12 col-xl-8">
                    <div class="title-label">Тип навеса</div>
                    <div class="row item-list">
                        <template v-for="item in data.gets.carport">
                            <div class="col col-12 col-md-4">
                                <get-item :data="item"></get-item>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-xl-2">
                    <div class="title-label">Бетонная плита</div>
                    <template v-for="(n, i) in 7">
                        <radio
                            :active="i == data.stovecount"
                            :label="getLabelStove(i)"
                            @click="setCountStove(i)"
                        ></radio>
                    </template>
                </div>
                <div class="col-12 col-md-6 col-xl-2">
                    <div class="title-label">Теплый пол</div>
                    <template v-for="(n, i) in 7">
                        <radio
                            :active="i == data.floorcount"
                            :label="getLabelFloor(i)"
                            @click="setCountFloor(i)"
                        ></radio>                 
                    </template>   
                </div>
            </div>
            <div class="row more">
                <div class="col-12 col-md-6 col-xl-4">
                    <div class="title-label">Тип котла</div>
                    <div class="row item-list">
                        <template v-for="item in data.gets.boiler">
                            <div class="col col-12 col-md-6">
                                <get-item :data="item" type="medium"></get-item>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                    <div class="title-label">Система очистки</div>
                    <div class="row item-list">
                        <template v-for="item in data.gets.cleaner">
                            <div class="col col-12 col-md-6">
                                <get-item :data="item" type="medium"></get-item>
                            </div>
                        </template>
                    </div>                    
                </div>
                <div class="col-12 col-md-6 col-xl-4">
                    <div class="title-label">Опции</div>
                    <div class="row item-list">
                        <template v-for="item in data.gets.option">
                            <div class="col col-12 col-md-6">
                                <get-item :data="item" type="medium"></get-item>
                            </div>
                        </template>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import radio from "@/components/radio.vue"
import check from "@/components/check.vue"
import GetItem from "@/components/GetItem.vue"

export default {
    components: { radio, check, GetItem },
    name: "StepPost",
    props: {
        calc: Object,
    },
    data() {
        return {
            data: {},
        }
    },
    created() {
        this.data = this.calc.data.construction
    },
    updated() {
        this.data = this.calc.data.construction
        this.$store.dispatch("total")
    },
    methods: {
        total: function () {},
        getLabelStove: function (n) {
            let label = "Без плит"
            if (n == 1) label = n + " плита"
            if (n > 1) label = n + " плиты"
            if (n > 4) label = n + " плит"
            return label
        },

        setCountStove: function (n) {
            this.data.stovecount = n
        },

        getLabelFloor: function (n) {
            let label = "Без пола"
            if (n == 1) label = n + " пол"
            if (n > 1) label = n + " пола"
            if (n > 4) label = n + " полов"
            return label
        },

        setCountFloor: function (n) {
            this.data.floorcount = n
        },        
    },
}
</script>


