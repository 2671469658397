<template>
    <div class="vuecalc">
        <div class="container">
            <div class="h3 main-title">Калькулятор</div>
            <div class="row no-gutters types-wrapper">
                <template v-for="(type, index) in $store.state.types">
                    <div class="col-12 col-md-auto" @click="setCalc(index)">
                        <div
                            class="type"
                            :class="{ active: $store.state.calc == type }"
                        >
                            <img :src="'/vuecalc/icons/' + type.icon" alt="" />
                            <div
                                class="caption"
                                v-html="type.caption.replace(' ', '<br>')"
                            ></div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="row no-gutters steps-wrapper">
                <template v-for="(step, index) in $store.state.calc.steps">
                    <div
                        class="col-12 col-md-4 col-xl-auto"
                        @click="setStep(step)"
                    >
                        <div
                            class="step"
                            :class="{
                                active: isActiveStep(step),
                                current: $store.state.calc.step == step,
                            }"
                        >
                            <div class="index">{{ index + 1 }}</div>
                            <div class="line"></div>
                            <div
                                class="caption"
                                v-html="$store.state.calc.data[step].caption"
                            ></div>
                            <div class="total">
                                <template
                                    v-if="
                                        $store.state.calc.data[step].total > 0
                                    "
                                >
                                    {{ $store.state.calc.data[step].total }} ₽
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
            <component
                :is="'Step' + $store.state.calc.step"
                :calc="$store.state.calc"
            ></component>
        </div>
    </div>
</template>

<script>
import Steppost from "@/components/StepPost.vue"
import Stepcontroller from "@/components/StepController.vue"
import Stepequipment from "@/components/StepEquipment.vue"
import Stepconstruction from "@/components/StepConstruction.vue"
import Steptotal from "@/components/StepTotal.vue"
import Stepmodul from "@/components/StepModul.vue"
export default {
    name: "App",
    components: {
        Steppost,
        Stepcontroller,
        Stepequipment,
        Stepconstruction,
        Stepmodul,
        Steptotal,
    },

    created() {
        let self = this
        // получаем цены
        this.axios
            .post(this.$store.state.get, {
                event: "get-prices",
                param: {},
            })
            .then(function (response) {
                let price = response.data
                self.$store.state.data.post.price = price.post*1
                self.$store.state.data.post.priceConsole = price.post_consol*1

                self.$store.state.data.controller.types[0].price = price.pult_standart*1
                self.$store.state.data.controller.types[1].price = price.pult_premium*1
                self.$store.state.data.controller.types[2].price = price.pult_luxe*1
                
                self.$store.state.data.equipment.sinkprice = price.rakovina*1

                self.$store.state.data.construction.stoveprice = price.plita*1
                self.$store.state.data.construction.floorprice = price.pol*1

                self.$store.commit("cloneData")
                self.$store.commit("setCalc", { type: 0 })
                self.$store.dispatch("total")
            })
            .catch(function (error) {
                // handle error
                console.log("response error, get post minmax")
            })

        // this.$store.commit("cloneData")
        // this.$store.commit("setCalc", { type: 0 })
        // this.$store.dispatch('total')
    },
    updated() {
        this.$store.dispatch("total")
    },

    methods: {
        firstLetterCaps: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1)
        },
        setCalc: function (index) {
            this.$store.commit("setCalc", { type: index })
        },
        setStep: function (step) {
            this.$store.commit("setStep", { step: step })
            // this.$store.state.calc.step = step
        },
        isActiveStep: function (step) {
            let current = this.$store.state.calc.step
            let flag = false
            let find = false
            this.$store.state.calc.steps.forEach((element) => {
                if (step == element) {
                    if (!find) flag = true
                }
                if (element == current) find = true
            })
            return flag
        },
    },
}
</script>
